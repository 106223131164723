export const GET_DASHBOARD_REQUEST = 'GET_DASHBOARD_REQUEST'
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS'
export const GET_DASHBOARD_FAILED = 'GET_DASHBOARD_FAILED'

export const GET_QR_CODE_LIST_REQUEST = 'GET_QR_CODE_LIST_REQUEST'
export const GET_QR_CODE_LIST_SUCCESS = 'GET_QR_CODE_LIST_SUCCESS'
export const GET_QR_CODE_LIST_FAILED = 'GET_QR_CODE_LIST_FAILED'

// export const GET_DASHBOARD_REQUEST = 'GET_DASHBOARD_REQUEST'
// export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS'
// export const GET_DASHBOARD_FAILED = 'GET_DASHBOARD_FAILED'

 